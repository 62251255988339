import type { Ratio } from '@canalplus/mycanal-commons';
import { getRatioClassname } from '@canalplus/mycanal-sharedcomponent';
import type { ApiV2LiveGridFeature } from '@dce-front/hodor-types/api/v2/live_grid/definitions';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import IconMultiLive from '../../../assets/svg/multiLive.svg';
import LoadableMultiLiveSetupContainer from '../../../templates/MultiLiveSetup';
import LiveGridModal from '../LiveGridModal/LiveGridModal';
import styles from './LiveGridFeatureCard.css';

const cx = classNames.bind(styles);

type LiveGridFeatureCardProps = {
  feature?: ApiV2LiveGridFeature;
  openModal?: () => void;
  ratio: Ratio;
};

function LiveGridFeatureCard({
  openModal = () => null,
  feature,
  ratio,
}: LiveGridFeatureCardProps): JSX.Element {
  return (
    <div>
      <button
        className={cx('LiveGridFeatureCard', getRatioClassname(ratio))}
        onClick={() => openModal()}
        type="button"
      >
        <div className={cx('LiveGridFeatureCard--detail')}>
          <IconMultiLive className={cx('LiveGridFeatureCard__icon')} />
          <strong className={cx('LiveGridFeatureCard__title')}>
            {feature?.title}
          </strong>
        </div>
      </button>
    </div>
  );
}

type LiveGridFeatureCardContainerProps = Omit<
  LiveGridFeatureCardProps,
  'openModal'
> & {
  displayPlaceholderChannels?: boolean;
  isPerso?: boolean;
};

function LiveGridFeatureCardContainer({
  displayPlaceholderChannels = false,
  feature,
  ratio,
  isPerso,
}: LiveGridFeatureCardContainerProps): JSX.Element | null {
  if (!feature) {
    return null;
  }

  return (
    <LiveGridModal
      modalContent={
        <LoadableMultiLiveSetupContainer
          displayPlaceholderChannels={displayPlaceholderChannels}
          isPerso={isPerso}
          url={feature.onClick?.URLPage}
          onClickParameters={feature.onClick?.parameters}
        />
      }
      triggerModalElement={
        <LiveGridFeatureCard feature={feature} ratio={ratio} />
      }
    />
  );
}

export default LiveGridFeatureCardContainer;
