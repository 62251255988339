import { Media } from '@canalplus/dive';
import { DEFAULT_RATIO, isRatio, Ratio } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2LiveGridFeature } from '@dce-front/hodor-types/api/v2/live_grid/definitions';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import classNames from 'classnames';
import { useCallback, useEffect, type JSX } from 'react';
import { useSelector } from 'react-redux';
import LazyLoader from '../../../components/LazyLoader/LazyLoader';
import LiveGridCard from '../../../components/LiveGrid/LiveGridCard/LiveGridCard';
import LiveGridFeatureCard from '../../../components/LiveGrid/LiveGridFeatureCard/LiveGridFeatureCard';
import { ProspectLoginMessageConnected } from '../../../components/LiveGrid/ProspectLoginMessage/ProspectLoginMessageConnected';
import { PlayerPlatform } from '../../../constants/playerPlatforms';
import {
  getLazyLoaderContentGridOptions,
  StrateMode,
} from '../../../constants/strates';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import {
  formatMultiLivePlayerData,
  useLiveChannelsFilter,
} from '../../../helpers/liveTV/liveTV-helper';
import { useTranslation } from '../../../lang';
import {
  getFeatureToggleMultiLiveSelector,
  userStatusSelector,
} from '../../../store/slices/application-selectors';
import { launchPlayerFullScreen } from '../../../store/slices/player-thunk';
import { locationSearchChannelSelector } from '../../../store/slices/routing-selectors';
import styles from './LiveGridTemplate.css';
import { LiveGridVirtual } from './LiveGridVirtual';

export type LiveGridTemplateProps = {
  channels: ApiV2BroadcastChannel[];
  nbPlaceholderItems: number;
  context?: ApiV2Context;
  displayPlaceholderChannels?: boolean;
  features?: ApiV2LiveGridFeature[];
  isAdult?: boolean;
  isPerso?: boolean;
  ratio?: Ratio;
  refetchLiveGridRequest: () => void;
};

function LiveGridTemplate({
  channels,
  context,
  displayPlaceholderChannels = false,
  features = [],
  isAdult,
  isPerso = false,
  nbPlaceholderItems,
  ratio = DEFAULT_RATIO,
  refetchLiveGridRequest,
}: LiveGridTemplateProps): JSX.Element {
  const dispatch = useAppDispatch();
  const userStatus = useSelector(userStatusSelector);
  const isProspect = userStatus === getPublicConfig().user_status.prospect;

  const { t } = useTranslation();

  const isFeatMultiLive = useInvariantSelector(
    getFeatureToggleMultiLiveSelector,
  );
  const epgIDsToLaunchOnMount = useSelector(locationSearchChannelSelector);

  const imageRatio = isRatio(DEFAULT_RATIO) ? DEFAULT_RATIO : Ratio.Ratio169;

  const filteredChannels = useLiveChannelsFilter(
    channels,
    refetchLiveGridRequest,
    displayPlaceholderChannels,
  );

  // If a channel parameter is in the URL, we launch that channel
  useEffect(() => {
    if (epgIDsToLaunchOnMount) {
      if (epgIDsToLaunchOnMount.length === 1) {
        const channelToLaunch = {
          channel: { epgID: epgIDsToLaunchOnMount[0] },
        };
        dispatch(
          launchPlayerFullScreen({
            data: channelToLaunch,
            type: StrateMode.LiveTv,
          }),
        );
      } else if (epgIDsToLaunchOnMount.length > 1) {
        const playerData = formatMultiLivePlayerData(epgIDsToLaunchOnMount);
        dispatch(
          launchPlayerFullScreen({
            data: playerData,
            type: PlayerPlatform.Multi,
          }),
        );
      }
    }
  }, [dispatch, epgIDsToLaunchOnMount]);

  const wrapCard = useCallback(
    (element: JSX.Element, key?: string | number) => (
      <li
        className={classNames(
          styles.LiveGridTemplate__gridItem,
          styles['liveGrid--focus'],
        )}
        key={String(key)}
      >
        {element}
      </li>
    ),
    [],
  );

  // If no channels are present, render placeholder cards.
  const listToRender: (ApiV2BroadcastChannel | undefined)[] =
    filteredChannels?.length
      ? filteredChannels
      : Array.from({ length: nbPlaceholderItems });

  const multiliveFeature = features.find(
    (feature) => feature?.onClick?.displayTemplate === Template.MultiLiveSetup,
  );
  const hasMultiliveFeature = isFeatMultiLive
    ? Boolean(multiliveFeature)
    : false;

  const { increment, initialDisplayCount } = getLazyLoaderContentGridOptions(
    {},
  );

  if ($_BUILD_RENDERMODE_CSR) {
    return (
      <LiveGridVirtual
        ratio={ratio}
        listToRender={listToRender}
        isPerso={isPerso}
        isAdult={isAdult}
        hasMultiliveFeature={hasMultiliveFeature}
        multiliveFeature={multiliveFeature}
        displayPlaceholderChannels={displayPlaceholderChannels}
        context={context}
      />
    );
  }

  return (
    <>
      <ul
        data-ratio={imageRatio}
        className={classNames(styles.LiveGridTemplate__grid, 'liveGrid')}
        aria-label={t('LiveGrid.ariaLabel')}
      >
        <LazyLoader
          initialDisplayCount={initialDisplayCount}
          loadMoreCount={increment}
          enableLazyLoad={!!filteredChannels?.length}
        >
          {/* Multi-live setup card */}
          {hasMultiliveFeature &&
            wrapCard(
              <LiveGridFeatureCard
                displayPlaceholderChannels={displayPlaceholderChannels}
                feature={multiliveFeature}
                isPerso={isPerso}
                ratio={imageRatio}
              />,
              multiliveFeature?.contentID,
            )}

          {/* Channels */}
          {listToRender.map((channel, index: number) => {
            const id =
              (typeof channel !== 'undefined' && channel?.epgID) || index;

            const trackingContext: ApiV2Context | undefined = context
              ? { ...context, context_list_position: index + 1 }
              : undefined;

            return wrapCard(
              channel ? (
                <LiveGridCard
                  channel={channel}
                  context={trackingContext}
                  ratio={ratio}
                />
              ) : (
                <Media aspectRatio={ratio as Ratio} />
              ),
              id,
            );
          })}
        </LazyLoader>
      </ul>
      {isProspect && (
        <div className={styles.LiveGridTemplate__prospectLoginMessageWrap}>
          <ProspectLoginMessageConnected />
        </div>
      )}
    </>
  );
}

export default LiveGridTemplate;
